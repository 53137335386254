@switch (currentStep) {
  @case (AuthenticationFlowStep.SignIn) {
    <app-sign-in-step (stepEvent)="processStepEvent($event)" @fadeIn></app-sign-in-step>
  }
  @case (AuthenticationFlowStep.ForgotPassword) {
    <app-forgot-password-step (stepEvent)="processStepEvent($event)"
                              [userName]="currentUserEmail"
                              @fadeIn></app-forgot-password-step>
  }
  @case (AuthenticationFlowStep.NewPassword) {
    <app-new-password-step @fadeIn></app-new-password-step>
  }
  @case (AuthenticationFlowStep.ForgotPasswordVerificationCode) {
    <app-change-password-with-verification-code-step (stepEvent)="processStepEvent($event)"
                                                     [userName]="currentUserEmail"
                                                     @fadeIn></app-change-password-with-verification-code-step>
  }
  @case (AuthenticationFlowStep.ConfirmWithTOTP) {
    <app-confirm-with-totp-code-step @fadeIn></app-confirm-with-totp-code-step>
  }
}

