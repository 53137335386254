<div class="flex size-full flex-col items-center justify-center">
  <mat-icon class="trace-logo svg-icon-accent-main-color trace-rotate3d" svgIcon="trace-leaf-logo"></mat-icon>
  <div>
    <div>
      <h5>Login out from the platform</h5>
    </div>
    <div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>
