<mat-card [formGroup]="newPasswordForm">
  <mat-card-header>
    <mat-card-title>Define your new password</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="flex flex-col gap-2 pt-4">
      <mat-form-field hideRequiredMarker>
        <mat-label>New password</mat-label>
        <input matInput formControlName="newPassword" type="password">
      </mat-form-field>
      <mat-form-field hideRequiredMarker>
        <mat-label>Confirm password</mat-label>
        <input matInput formControlName="confirmPassword" type="password">
        @if (newPasswordForm.errors?.comparePassword) {
          <mat-error>
            Passwords doesn't match
          </mat-error>
        }
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button (click)="completeNewPassword()" mat-raised-button color="primary"
            [disabled]="newPasswordForm.invalid || confirmSignInning"
            [appAddLoadingIcon]="confirmSignInning">Change password
    </button>
  </mat-card-actions>
</mat-card>
