<div class="flex size-full flex-col items-center justify-center">
  <mat-card>
    @if (isLoading) {
      <mat-spinner>
      </mat-spinner>
    }
    @if (!isLoading) {
      <mat-card-content>
        @if (error) {
          <h2>{{ error }}</h2>
        }
        @if (authScope === allAuthScopes.Basic) {
          <div>
            <h3>Thanks for your interest</h3>
          </div>
        }
        @if (authScope === allAuthScopes.Full && tenantResults.length > 0) {
          <div>
            <h3>Connection to {{ providerName }} successful</h3>
            <div>
              You have successfully authorised {{ companyName }}'s connection to {{ providerName }}. Below are the
              connected
              organisation/s.
              <ul>
                @for (tenant of tenantResults; track tenant.id) {
                  <li>
                    {{ tenant.name }}
                  </li>
                }
              </ul>
              <p>If you have a Trace account, you can click the link below to return to the Trace portal. Otherwise, you
                may
                close this window.</p>
            </div>
          </div>
        }
      </mat-card-content>
    }

    @if (!isLoading) {
      <mat-dialog-actions align="end">
        <button mat-stroked-button routerLink="/portal">Home</button>
      </mat-dialog-actions>
    }
  </mat-card>

</div>

