<div class="flex size-full flex-col items-center justify-center">
  <mat-card appearance="outlined">
    <mat-card-content>
      <div class="flex flex-col sm:flex-row">
        <div class="flex flex-col items-center justify-center">
          <app-animated-logo class="w-96"></app-animated-logo>
        </div>
        <div class="flex flex-col gap-4">
          <app-authentication-flow [currentStep]="currentAuthenticationStep"></app-authentication-flow>
          <div class="flex flex-col gap-2">
            <button mat-stroked-button (click)="loginWithGoogle()" class="google-button">
              <mat-icon svgIcon="google"></mat-icon>
              Sign In using Google
            </button>
            <button mat-stroked-button (click)="loginWithMicrosoft()" class="microsoft-button"
                    [disabled]="disableMicrosoftButton">
              <mat-icon svgIcon="microsoft"></mat-icon>
              Sign In using Microsoft
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
