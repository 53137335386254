<mat-card [formGroup]="changePasswordWithVerificationCodeForm">
  <mat-card-header>
    <mat-card-title>Change your password</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="flex flex-col gap-2 pt-4">
      <mat-form-field hideRequiredMarker>
        <mat-label>Reset password code</mat-label>
        <input matInput formControlName="verificationCode" mask="000 000" class="text-center !text-2xl">
      </mat-form-field>
      <mat-form-field hideRequiredMarker>
        <mat-label>New password</mat-label>
        <input matInput formControlName="verificationNewPassword" type="password">
      </mat-form-field>
      <mat-form-field hideRequiredMarker>
        <mat-label>Confirm password</mat-label>
        <input matInput formControlName="verificationConfirmPassword" type="password">
        @if (changePasswordWithVerificationCodeForm.errors?.comparePassword) {
          <mat-error>
            Passwords doesn't match
          </mat-error>
        }
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button (click)="cancel()" mat-raised-button>Cancel</button>
    <button (click)="changePasswordWithVerificationCode()" mat-raised-button color="primary"
            [disabled]="changePasswordWithVerificationCodeForm.invalid || confirmingResetPassword"
            [appAddLoadingIcon]="confirmingResetPassword">
      Change
    </button>
  </mat-card-actions>
</mat-card>
