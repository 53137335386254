import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ReminderInstanceUserService} from '../api/services/reminder-instance-user.service';
import {ReminderInstanceUserResponse} from '../api/models/reminder-instance-user-response';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  id: string;
  response: ReminderInstanceUserResponse;

  constructor(private activatedRoute: ActivatedRoute,
              private reminderInstanceUserService: ReminderInstanceUserService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.loadUserSubscription();
    });
  }

  unsubscribe() {
    this.reminderInstanceUserService.unsubscribe({
      body: {
        id: this.id
      }
    }).subscribe(() => {
      this.loadUserSubscription();
    })
  }

  private loadUserSubscription() {
    this.reminderInstanceUserService.getById({
      body: {
        id: this.id
      }
    }).subscribe(res => {
      if (res) {
        this.response = res;
      }
    });
  }
}
