<div class="flex size-full flex-col items-center justify-center">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <h2>Connecting with provider...</h2>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      @if (isLoading) {
        <mat-spinner>
        </mat-spinner>
      }
      @if (!isLoading) {
        <div>
          @if (error) {
            <h4>{{ error }}</h4>
          }
        </div>
      }
    </mat-card-content>
  </mat-card>
</div>
