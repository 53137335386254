<div class="flex size-full flex-col items-center justify-center">
  <mat-icon class="trace-logo svg-icon-accent-main-color trace-rotate3d" svgIcon="trace-leaf-logo"></mat-icon>
  @if (!errorMessage) {
    <div>
      <div>
        <h5>Preparing the platform...</h5>
      </div>
      <div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
  }

  @if (errorMessage) {
    <div>
      <div>
        <h4>{{ errorMessage }}</h4>
      </div>
      <div class="flex flex-row items-center justify-center">
        <button mat-stroked-button color="primary" (click)="goHome()">Go to home</button>
      </div>
    </div>
  }
</div>
