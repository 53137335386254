import {Component} from '@angular/core';
import {TypedFormBuilderService} from '../../../../../modules/global/services/typed-form-builder.service';
import {confirmSignIn} from 'aws-amplify/auth';
import {AuthService} from '../../../../../modules/auth/auth.service';
import {NotificationService} from '../../../../../modules/global/services/notification.service';

@Component({
  selector: 'app-new-password-step',
  templateUrl: './new-password-step.component.html',
  styleUrl: './new-password-step.component.scss'
})
export class NewPasswordStepComponent {
  newPasswordForm = this.formBuilderService.newPasswordForm();
  confirmSignInning = false;

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private formBuilderService: TypedFormBuilderService) {
  }

  completeNewPassword() {
    this.confirmSignInning = true;
    const formValue = this.newPasswordForm.value;
    confirmSignIn({challengeResponse: formValue.newPassword}).then(() => {
      this.confirmSignInning = false;
      this.authService.navigateToRejectedRoute();
    }, error => {
      this.notificationService.showError(error.message);
      this.confirmSignInning = false;
    });
  }
}
