<div class="flex h-full flex-col px-3">
  <div class="flex flex-col items-center sm:h-full">
    <div class="mx-3 mt-10 flex min-w-[300px] max-w-[800px] flex-col">
      <div>
        <div class="flex flex-col items-center justify-center sm:flex-row sm:items-end sm:justify-start">
          <div class="flex flex-row items-center justify-center">
            <mat-icon class="trace-logo" routerLink="/" svgIcon="trace-logo"></mat-icon>
          </div>
          <h2>Start your journey to Net Zero</h2>
        </div>

        <p class="mb-8 mt-3">
          Tell us about the business you’re creating an account for, <span class="mat-body-strong">so we can get it all setup. </span>
          Add the key
          contact and business details, and we’ll be in touch when it’s ready for you to start measuring your
          carbon emissions.
        </p>
      </div>

      <mat-card [formGroup]="companyOnBoardForm">
        <mat-card-content class="m-5">
          <div class="flex flex-col space-y-4">

            <div class="flex flex-col gap-5 sm:flex-row">
              <mat-form-field class="flex flex-auto flex-col" appearance="outline">
                <mat-label>First name</mat-label>
                <input formControlName="firstName" matInput>
              </mat-form-field>

              <mat-form-field class="flex flex-auto flex-col" appearance="outline">
                <mat-label>Last name</mat-label>
                <input formControlName="lastName" matInput>
              </mat-form-field>
            </div>

            <div class="flex flex-col gap-5 sm:flex-row">

              <mat-form-field [ngClass]="{'invalid-input': (companyOnBoardForm.controls.email.touched || companyOnBoardForm.controls.email.dirty) &&
                                  companyOnBoardForm.controls.email.invalid && !companyOnBoardForm.controls.email.errors.emailTaken}"
                              class="flex flex-auto flex-col"
                              appearance="outline">
                <mat-label>Email address</mat-label>
                <input formControlName="email" matInput type="email">
                <mat-hint>
                  @if (companyOnBoardForm.controls.email.status === 'PENDING') {
                    Validating ...
                  }
                  @if (companyOnBoardForm.controls.email.status === 'VALID') {
                    Email is available!
                  }
                </mat-hint>
                <mat-error>
                  @if (companyOnBoardForm.controls.email.invalid && !companyOnBoardForm.controls.email.errors.emailTaken) {
                    Invalid email
                  }
                  @if (companyOnBoardForm.controls.email.errors && companyOnBoardForm.controls.email.errors.emailTaken) {
                    This email is already registered with us
                  }
                </mat-error>
              </mat-form-field>

              <mat-form-field [ngClass]="{'invalid-input': companyOnBoardForm.controls.phone.invalid &&
                                   (companyOnBoardForm.controls.phone.touched || companyOnBoardForm.controls.phone.dirty)}"
                              class="flex flex-auto flex-col"
                              appearance="outline">
                <mat-label>Phone number</mat-label>
                <input [mask]="masks.phoneMask" [validation]="false"
                       formControlName="phone" matInput>
                @if (companyOnBoardForm.controls.phone.invalid) {
                  <mat-error>Please provide a valid
                    phone
                    number
                  </mat-error>
                }
              </mat-form-field>

            </div>

            <div class="flex flex-col gap-5 sm:flex-row">

              <mat-form-field appearance="outline"
                              class="flex flex-auto flex-col">
                <mat-label>Company name</mat-label>
                <input formControlName="companyName" matInput>
                <mat-hint>
                  @if (companyOnBoardForm.controls.companyName.status === 'PENDING') {
                    Validating ...
                  }
                  @if (companyOnBoardForm.controls.companyName.status === 'VALID') {
                    <ng-container
                      class="valid">
                      Company is available!
                    </ng-container>
                  }
                </mat-hint>
                @if (companyOnBoardForm.controls.companyName.errors && companyOnBoardForm.controls.companyName.errors.companyNameTaken) {
                  <mat-error
                  >
                    <ng-container
                      class="invalid">
                      We already have a company with that name
                    </ng-container>
                  </mat-error>
                }
              </mat-form-field>

              <mat-form-field [ngClass]="{'invalid-input': companyOnBoardForm.controls.website.invalid  &&
                                   (companyOnBoardForm.controls.website.touched || companyOnBoardForm.controls.website.dirty) }"
                              class="flex flex-auto flex-col"
                              appearance="outline">
                <mat-label>Website</mat-label>
                <input formControlName="website" matInput>
                <mat-hint>e.g. https://www.our-trace.com</mat-hint>
                @if (companyOnBoardForm.controls.website.invalid) {
                  <mat-error>Please enter a valid URL
                  </mat-error>
                }

              </mat-form-field>
            </div>

            <div class="flex flex-col gap-5 sm:flex-row">
              <mat-form-field
                [ngClass]="{'invalid-input': companyOnBoardForm.controls.employeeCount.invalid}"
                class="flex flex-auto flex-col sm:w-2/5"
                appearance="outline">
                <input [mask]="masks.decimalMask"
                       [thousandSeparator]="masks.thousandSeparator"
                       formControlName="employeeCount" matInput>
                <mat-label>Number of employees</mat-label>
              </mat-form-field>
              <mat-form-field appearance="outline"
                              class="flex flex-auto flex-col sm:w-3/5">
                <mat-label>Industry</mat-label>
                <mat-select formControlName="industry">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="industryFilterControl"
                                           noEntriesFoundLabel="No results found"
                                           placeholderLabel="Search">
                      <mat-icon fontIcon="icon-cancel" ngxMatSelectSearchClear></mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  @for (value of industryValues; track value.id) {
                    <mat-option [value]="value.id">
                      {{ value.name }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="flex flex-col items-center justify-center px-5 sm:sticky sm:bottom-0 sm:h-16 sm:flex-row sm:justify-normal">
    <div (click)="backToBusiness()" class="invisible z-10 flex cursor-pointer items-center underline sm:visible">
      <mat-icon fontIcon="icon-arrow-left"></mat-icon>
      <span>Back to Trace for business</span>
    </div>
    <div class="sm:flex-1"></div>
    <button (click)="openPaymentStep()" color="primary"
            [disabled]="companyOnBoardForm.invalid || companyCreationInProgress"
            mat-flat-button>
      <span>Add Payment Method</span>
    </button>
  </div>
</div>

