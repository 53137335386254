/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BackofficeMessageResult } from '../models/backoffice-message-result';
import { PortalMessageResult } from '../models/portal-message-result';
import { testWebsocketMessage } from '../fn/utils/test-websocket-message';
import { TestWebsocketMessage$Params } from '../fn/utils/test-websocket-message';

@Injectable({ providedIn: 'root' })
export class UtilsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1UtilsTestWebsocketMessageGet()` */
  static readonly V1UtilsTestWebsocketMessageGetPath = '/v1/utils/test-websocket-message';

  /**
   * Just to get the Messages Models in the client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testWebsocketMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  testWebsocketMessage$Response(params?: TestWebsocketMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<(PortalMessageResult | BackofficeMessageResult)>> {
    return testWebsocketMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Just to get the Messages Models in the client.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `testWebsocketMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  testWebsocketMessage(params?: TestWebsocketMessage$Params, context?: HttpContext): Observable<(PortalMessageResult | BackofficeMessageResult)> {
    return this.testWebsocketMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<(PortalMessageResult | BackofficeMessageResult)>): (PortalMessageResult | BackofficeMessageResult) => r.body)
    );
  }

}
