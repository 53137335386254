import {PathLocationStrategy, APP_BASE_HREF, PlatformLocation} from '@angular/common';
import {Optional, Inject, Injectable} from '@angular/core';
import {UrlSerializer} from '@angular/router';

/// This is used to keep the query string parameters among pages
/// For example Google Analytics debug needs to keep the parameter in order to use the debugger
@Injectable()
export class PathPreserveQueryLocationStrategy extends PathLocationStrategy {
  private preservedQueryStringParams = ['gtm_debug'];

  private get search(): string {
    return this.platformLocation?.search ?? '';
  }

  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
  ) {
    super(platformLocation, _baseHref);
  }

  prepareExternalUrl(internal: string): string {
    const path = super.prepareExternalUrl(internal);
    const existingURLSearchParams = new URLSearchParams(this.search);
    const entries = [...existingURLSearchParams.entries()].filter(v => this.preservedQueryStringParams.indexOf(v[0]) >= 0);
    const existingQueryParams = Object.fromEntries(entries);
    const urlTree = this.urlSerializer.parse(path);
    const nextQueryParams = urlTree.queryParams;
    urlTree.queryParams = {...existingQueryParams, ...nextQueryParams};
    return urlTree.toString();
  }

}
