<div class="container" fxLayout="column" fxLayoutAlign="center center">
  <div class="main-content">
    <mat-card>
      <mat-card-header>
        <h2>{{initiative?.name}}</h2>
      </mat-card-header>
      <mat-card-content>
        <ng-container>
          @if (initiative) {
            <div fxLayout="column">
              <div fxFlex fxLayout.lt-md="column">
                <div class="margin-bottom-20" fxLayout="column" fxLayoutGap="8px" fxFlex.lt-md="100">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="title">Cost:</div>
                    <mat-icon class="svg-icon-primary-color" [svgIcon]="EffortIconMapping[initiative.effort]"></mat-icon>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="title">Impact:</div>
                    <mat-icon class="svg-icon-accent-color" [svgIcon]="ImpactIconMapping[initiative.impact]"></mat-icon>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <div class="title">Category:</div>
                    <div class="category-color-display" [style.background]="initiative.category.baseColorHex"></div>
                    {{initiative.category.name}}
                  </div>
                  @if (initiative.activeCompanyCount > 0) {
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <div class="title">Popularity:</div>
                      <div>
                        <mat-icon fontIcon="icon-users"></mat-icon>
                        {{initiative.activeCompanyCount}} others doing this
                      </div>
                    </div>
                  }
                </div>
              </div>
              <mat-divider></mat-divider>
              <h4>About</h4>
              <quill-view-html class="detail-view" [content]="initiative.content"></quill-view-html>
              <mat-divider></mat-divider>
              @if (initiative.initiativeActions.length > 0) {
                <div>
                  <h4>Checklist</h4>
                  @for (action of initiative.initiativeActions; track action.id) {
                    <div>
                      <ul>
                        <li>{{action.name}}</li>
                      </ul>
                    </div>
                  }
                </div>
              }
              @if (assets.length > 0) {
                <mat-divider></mat-divider>
                <h4>Assets</h4>
                @for (asset of assets; track asset) {
                  <div>
                    <div fxLayoutAlign=" center">
                      @if (asset.type === AssetType.S3FileObject) {
                        <mat-icon
                          class="margin-right-16"
                        fontIcon="icon-download"></mat-icon>
                      }
                      @if (asset.type === AssetType.Link) {
                        <mat-icon
                          class="margin-right-16"
                        fontIcon="icon-link"></mat-icon>
                      }
                      {{asset.title}} ({{asset.typeOfContent}})
                    </div>
                  </div>
                }
              }
            </div>
          }
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>
