import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {BufferedClientLoggerService} from './modules/global/services/buffered-client-logger.service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ClientLoggerService} from './api/services/client-logger.service';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {GATraceService} from './modules/global/services/ga-trace.service';
import {UserStore} from './modules/auth/store/user-store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(
    private userStore: UserStore,
    private bufferedClientLoggerService: BufferedClientLoggerService,
    private clientLoggerService: ClientLoggerService,
    private router: Router,
    private $gaTraceService: GATraceService,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer
  ) {
    matIconRegistry.setDefaultFontSetClass('trace');
    matIconRegistry.addSvgIcon(
      'trace-logo',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/trace.svg')
    );
    matIconRegistry.addSvgIcon(
      'trace-leaf-logo',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/trace-leaf.svg')
    );
    matIconRegistry.addSvgIcon(
      'google',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/btn_google_dark_normal_ios.svg')
    );
    matIconRegistry.addSvgIcon(
      'microsoft',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/btn_microsoft_logo.svg')
    );
    matIconRegistry.addSvgIcon('dollar-sign',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/portal/svgs/icons/dollar-sign.svg'));
    matIconRegistry.addSvgIcon('2-dollar-signs',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/portal/svgs/icons/2-dollar-signs.svg'));
    matIconRegistry.addSvgIcon('3-dollar-signs',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/portal/svgs/icons/3-dollar-signs.svg'));
    matIconRegistry.addSvgIcon('leaf',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/portal/svgs/icons/leaf.svg'));
    matIconRegistry.addSvgIcon('2-leaves',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/portal/svgs/icons/2-leaves.svg'));
    matIconRegistry.addSvgIcon('3-leaves',
      domSanitizer.bypassSecurityTrustResourceUrl('../assets/portal/svgs/icons/3-leaves.svg'));
  }

  ngOnInit(): void {
    this.bufferedClientLoggerService.loggerEvents
      .pipe(
        debounceTime(5000),
        distinctUntilChanged())
      .subscribe(logItem => {
        this.clientLoggerService.createInternal({
          body: logItem
        }).subscribe({
          next: () => console.warn('Log sent to Server'),
          error: error => console.error('Sending log to server', error)
        });
      });

    const userStoreSub = this.userStore.currentUser$.subscribe(u => {
      this.$gaTraceService.setUserId(u?.id);
      this.$gaTraceService.setTrafficType(u?.email);
    });

    this.subscription.add(userStoreSub);

    this.subscription.add(this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.$gaTraceService.pageView(event.urlAfterRedirects);
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
