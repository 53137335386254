import {Component, OnInit, Renderer2} from '@angular/core';
import {FormControl} from '@angular/forms';
import {environment} from '../../environments/environment';
import {lastValueFrom} from 'rxjs';
import {debounceTime, finalize} from 'rxjs/operators';
import {masks} from '../system-constants/constants';
import {Router} from '@angular/router';
import {NotificationService} from '../modules/global/services/notification.service';
import {IndustryResponse} from '../api/models/industry-response';
import {IndustryService} from '../api/services/industry.service';
import {DynamicScriptLoadingService} from '../modules/global/services/dynamic-script-loading.service';
import {PaymentService} from '../api/services/payment.service';
import {MatDialog} from '@angular/material/dialog';
import {ProcessingPaymentDialogComponent} from './processing-payment-dialog/processing-payment-dialog.component';
import {TypedFormBuilderService} from '../modules/global/services/typed-form-builder.service';

declare let Chargebee;

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
  companyCreationInProgress: boolean;
  industryFilterControl = new FormControl();
  industryFullList: IndustryResponse[];
  industryValues: IndustryResponse[];
  companyOnBoardForm = this.typedFormBuilderService.companyOnBoardForm();

  constructor(
    private dialog: MatDialog,
    private typedFormBuilderService: TypedFormBuilderService,
    private router: Router,
    private render: Renderer2,
    private notificationService: NotificationService,
    private industryService: IndustryService,
    private dynamicScriptLoadingService: DynamicScriptLoadingService,
    private paymentService: PaymentService) {
    this.render.addClass(document.body, 'data-section');
  }

  ngOnInit(): void {
    this.industryService.list().subscribe(industries => {
      this.industryValues = industries;
      this.industryFullList = industries;
    });

    this.industryFilterControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(value => this.industryFilter(value));

    this.dynamicScriptLoadingService
      .loadDynamicScript(environment.chargeBeeScriptUrl)
      .then(() => {
        window['Chargebee'].init({
          site: environment.chargeBeeSite,
          publishableKey: environment.chargeBeeKey
        });
      });

    this.companyOnBoardForm.controls.website.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(value => {
        let website = value;
        if (!website.startsWith('http')) {
          website = `https://${website}`;
        }
        this.companyOnBoardForm.controls.website.setValue(website, {emitEvent: false});
      });
  }

  openPaymentStep() {
    this.companyCreationInProgress = true;
    const cbInstance = Chargebee.getInstance();
    cbInstance.logout();
    // noinspection JSUnusedGlobalSymbols false positive
    cbInstance.openCheckout({
      hostedPage: () => {
        const form = this.companyOnBoardForm.value;
        const hostedPage = this.paymentService.getHostedPage({
          body: {
            companyName: form.companyName,
            email: form.email,
            firstName: form.firstName,
            lastName: form.lastName,
            phoneNumber: form.phone,
            extraCompanyInformation: {
              website: form.website,
              industryEntityId: form.industry,
              numberOfFullTimeEmployees: form.employeeCount
            }
          }
        });
        return lastValueFrom(hostedPage);
      },

      error: () => {
        this.notificationService.showError('Error adding payment method');
        this.companyCreationInProgress = false;
      },

      close: () => {
        this.companyCreationInProgress = false;
      },

      success: (hostedPageId: string) => {
        cbInstance.logout();
        cbInstance.closeAll();
        const refLoading = this.dialog.open(ProcessingPaymentDialogComponent, {
          disableClose: true
        });
        this.paymentService.createFromHostedPage({
          body: {
            hostedPageId: hostedPageId
          }
        }).pipe(finalize(() => {
          refLoading.close();
          this.companyCreationInProgress = false;
        }))
          .subscribe(() => {
            this.router.navigate(['/get-started/success']).then();
          });
      }
    });
  }

  get masks() {
    return masks;
  }

  backToBusiness() {
    window.open(environment.traceForBusinessLink, '_blank');
  }

  private industryFilter(value: string) {
    if (value === null) return;

    this.industryValues = this.industryFullList.filter(industry => industry.name.toLowerCase().includes(value.toLowerCase()));
  }
}
