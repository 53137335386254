import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AccountingIntegrationService} from '../api/services/accounting-integration.service';
import {TenantResult} from '../api/models/tenant-result';
import {getOAuthProvider} from '../utils/function-utils';
import {finalize} from 'rxjs/operators';
import {AuthScope} from '../api/models/auth-scope';
import {environment} from '../../environments/environment';
import {OAuthProviderType} from '../api/models';

@Component({
  selector: 'app-provider-callback',
  templateUrl: './provider-callback.component.html',
  styleUrls: ['./provider-callback.component.scss']
})
export class ProviderCallbackComponent implements OnInit {
  error: string = null;
  tenantResults: TenantResult[] = [];
  isLoading = true;
  authScope: AuthScope = AuthScope.Full;
  allAuthScopes = AuthScope;
  companyName = '';
  providerName = '';

  constructor(
    private accountingIntegrationService: AccountingIntegrationService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const providerParam = this.activatedRoute.snapshot.params['provider'];
    const queryParams = this.activatedRoute.snapshot.queryParams;
    this.isLoading = true;
    const oAuthProvider = getOAuthProvider(providerParam);
    this.accountingIntegrationService.processFlow({
      body: {
        providerType: oAuthProvider,
        parameters: queryParams
      }
    }).pipe(finalize(() => this.isLoading = false))
      .subscribe(r => {
        this.error = r.error;
        this.tenantResults = r.tenantResults ?? [];
        this.authScope = r.authScope;
        // If AuthScope is Basic, we just have the Basic User Profile information, so we just need to redirect the user to the HubSpot Get a Quote form
        if (this.authScope == AuthScope.Basic && !r.error) {
          const referral = `${OAuthProviderType[oAuthProvider]} marketplace`;
          window.location.href = `${environment.traceGetAQuoteLink}?firstname=${r.userProfile.givenName}&lastname=${r.userProfile.familyName}&email=${r.userProfile.email}&how_did_you_find_out_about_us_=${referral}`;
        }

        this.providerName = OAuthProviderType[oAuthProvider];
        this.companyName = r.companyName;
      });
  }
}
