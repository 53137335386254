<div class="unsubscribe" fxLayout="row wrap" fxLayout.xs="column" fxFlexFill>
  <div fxFlex="6" class="side-bar" fxLayoutAlign="center start"
    fxLayoutAlign.lt-sm="center center">
    <mat-icon fxFlex="100" class="logo" svgIcon="trace-logo" routerLink="/"></mat-icon>
    <span fxHide.lt-sm fxFlex></span>
  </div>

  <div fxFlex="92" class="base">
    <div fxFlexFill fxLayout="column" fxLayoutAlign="start center">
      <div class="main-panel" fxLayout="column">
        <div class="row-wrapper" fxFlex="100"
          fxLayout="row wrap" fxLayout.lt-sm="column">
          <div fxFlex.lt-sm="100">
            @if (!response) {
              <div>
                <div class="title-raw">
                  <h2><strong>Subscription not found</strong></h2>
                  <span class="title-desc">
                    You are not currently subscribed to emails from trace.
                  </span>
                </div>
              </div>
            } @else {
              @if (response.isSubscribed) {
                <div>
                  <div class="title-raw">
                    <h2><strong>Unsubscribe from {{this.response.reminderName}}</strong></h2>
                    <span class="title-desc">
                      Your email address <strong>{{this.response.email}}</strong> will no longer receive emails about {{this.response.reminderName}} from Trace.
                    </span>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <button class="button"
                      (click)="unsubscribe()">
                      <span>Unsubscribe</span>
                    </button>
                  </div>
                </div>
              }
              @if (!response.isSubscribed) {
                <div>
                  <div class="title-raw">
                    <h2><strong>Thank you</strong></h2>
                    <span class="title-desc">
                      You will no longer receive emails about {{this.response.reminderName}} from Trace.
                    </span>
                  </div>
                </div>
              }
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
