<mat-card [formGroup]="confirmForm">
  <mat-card-header>
    <mat-card-title>MFA Code</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="flex flex-col gap-2 pt-4">
      <mat-form-field>
        <mat-label>Code</mat-label>
        <input matInput formControlName="code" mask="000 000" class="text-center !text-2xl">
      </mat-form-field>
      <mat-checkbox formControlName="rememberDevice">Remember this device</mat-checkbox>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button (click)="verify()" mat-raised-button color="primary" [disabled]="confirmForm.invalid || verifying"
            [appAddLoadingIcon]="verifying">Verify
    </button>
  </mat-card-actions>
</mat-card>
