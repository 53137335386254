import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TypedFormBuilderService} from '../../../../../modules/global/services/typed-form-builder.service';
import {confirmResetPassword} from 'aws-amplify/auth';
import {NotificationService} from '../../../../../modules/global/services/notification.service';
import {AuthenticationFlowEvent, AuthenticationFlowStep} from '../../authentication-flow.component';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-change-password-with-verification-code-step',
  templateUrl: './change-password-with-verification-code-step.component.html',
  styleUrl: './change-password-with-verification-code-step.component.scss'
})
export class ChangePasswordWithVerificationCodeStepComponent implements OnInit {
  @Input() userName: string;
  @Output() stepEvent = new EventEmitter<AuthenticationFlowEvent>();

  changePasswordWithVerificationCodeForm = this.formBuilderService.changePasswordWithVerificationCodeForm();
  confirmingResetPassword = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private formBuilderService: TypedFormBuilderService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(params => {
      const userName = params.get('user_id');
      const code = params.get('verification_code');
      if (userName && code) {
        this.userName = userName;
        this.changePasswordWithVerificationCodeForm.controls.verificationCode.setValue(code);
      }
    });
  }

  changePasswordWithVerificationCode() {
    this.confirmingResetPassword = true;
    const formValue = this.changePasswordWithVerificationCodeForm.value;
    confirmResetPassword({
      username: this.userName,
      confirmationCode: formValue.verificationCode,
      newPassword: formValue.verificationNewPassword
    }).then(() => {
      this.confirmingResetPassword = false;
      this.notificationService.showSuccess('Your password has been updated');
      this.stepEvent.emit({
        flowStep: AuthenticationFlowStep.SignIn,
        currentUserEmail: this.userName
      });
    }, error => {
      this.notificationService.showError(error.message);
      this.confirmingResetPassword = false;
    });
  }

  cancel() {
    this.stepEvent.emit({flowStep: AuthenticationFlowStep.SignIn, currentUserEmail: null});
  }
}
