import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreventRedirectGuard  {
  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.isPreventRedirectUrl(state.url))
    {
      return true;
    }

    const code = route.queryParamMap.get('code');

    if (code) {
      const keys = Object.keys(route.queryParams).filter(k => k !== 'code');
      const params = {};
      keys.forEach(k => params[k] = route.queryParams[k]);

      this.router.navigate(route.url.map(u => u.path), {
        queryParams: {
          oauthCode: code,
          ...params
        }
      }).then();
      return false;
    }

    return true;
  }

  private isPreventRedirectUrl(url: string): boolean {
    return url.startsWith('/oauth/');
  }
}
