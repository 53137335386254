import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-success-onboard',
  templateUrl: './success-onboard.component.html',
  styleUrls: ['./success-onboard.component.scss']
})
export class SuccessOnboardComponent implements OnInit {

  currentYear: number

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

}
