import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorMessageDialogInput} from '../components/error-message-dialog/error-message-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }
    return error.message ? error.message : error.toString();
  }

  getClientStack(error: Error): string {
    return error.stack;
  }

  getServerMessage(errorResponse: HttpErrorResponse): ErrorMessageDialogInput {
    if (errorResponse.status === 0) {
      return {title: 'There is an issue with the connection'};
    }
    if (errorResponse.status === 400) {
      const jsonError = typeof errorResponse.error == 'string' ?
        ErrorService.tryToParse(errorResponse.error) : errorResponse.error;

      if (jsonError) {
        const inputError = {title: jsonError.title, details: []};
        for (const key in jsonError.errors) {
          inputError.details.push(...jsonError.errors[key]);
        }
        return inputError;
      }
      return {title: errorResponse.error};
    }
    if (errorResponse.status === 401) {
      return {title: 'Not authorised operation'};
    }
    if (errorResponse.status === 500) {
      return {title: 'Server error, please retry later'};
    }
    return {title: errorResponse.message};
  }

  private static tryToParse(error: string): any {
    try {
      return JSON.parse(error);
    } catch (e) {
      return null;
    }
  }
}
