<div class="onboard-success" fxLayout="row wrap" fxLayout.xs="column" fxFlexFill>
  <div fxFlex="6" class="side-bar" fxLayoutAlign="center start"
       fxLayoutAlign.lt-sm="center center">
    <mat-icon fxFlex="100" class="logo" svgIcon="trace-logo" routerLink="/"></mat-icon>
    <span fxHide.lt-sm fxFlex></span>
  </div>

  <div fxFlex="94" class="base" fxLayoutAlign="center center">
    <div class="main-panel" fxLayoutAlign="center center">
      <div class="success-container">
        <h1 class="title">Nice one!</h1>
        <div class="msg">
          You’re almost there - please check your inbox for an email from no-reply&#64;our-trace.com to finish creating
          your
          account.
          <br><br>
          p.s. Don’t forget to check your spam folder & mark us as not junk for future notifications.
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-raised-button routerLink="/" color="primary">Go Home</button>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex fxLayout="row" style="" fxFlexFill></div>
  <div fxFlex fxLayout="row" class="copyright sticky" fxLayoutAlign.lt-sm="center center">
    <div fxLayout="row" fxLayoutAlign="center center" class="text">
      Copyright © {{currentYear}} Trace. All rights reserved.
    </div>
  </div>
</div>


