import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {InitiativeService} from '../api/services/initiative.service';
import {InitiativeDetailsResponse} from '../api/models/initiative-details-response';
import {
  EffortIconMapping,
  ImpactIconMapping
} from '../modules/portal/components/reduce/components/initiatives/initiatives.component';
import {getTypeOfContent, getTypeOfUrl} from '../utils/function-utils';
import {ReduceActionAssetResponse} from '../api/models/reduce-action-asset-response';
import {AssetType} from '../api/models';

@Component({
  selector: 'app-trace-initiative-public-view',
  templateUrl: './trace-initiative-public-view.component.html',
  styleUrls: ['./trace-initiative-public-view.component.scss']
})
export class TraceInitiativePublicViewComponent implements OnInit {
  id: string;
  initiative: InitiativeDetailsResponse;
  assets: AssetInformation[] = [];
  isLoading = true;

  constructor(private activateRoute: ActivatedRoute,
              private initiativeService: InitiativeService) {
  }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      this.id = params['id'];
      this.loadTraceInitiativeDetails();
    })
  }

  private loadTraceInitiativeDetails() {
    this.initiativeService.getTraceInitiativeDetails({
      body: {
        id: this.id
      }
    })
      .subscribe(initiative => {
        this.initiative = initiative;
        this.assets = initiative.assets.map(asset => ({
          title: asset.title,
          type: asset.type,
          typeOfContent: this.getTypeOfContent(asset)
        }) as AssetInformation);
        this.isLoading = false;
      });
  }

  private getTypeOfContent(asset: ReduceActionAssetResponse): string {
    if (asset.type === AssetType.Link)
      return getTypeOfUrl(asset.url);
    if (asset.type === AssetType.S3FileObject)
      return getTypeOfContent(asset.s3FileObject.contentType);
    return '';
  }

  protected readonly EffortIconMapping = EffortIconMapping;
  protected readonly ImpactIconMapping = ImpactIconMapping;
  protected readonly AssetType = AssetType;
}

export interface AssetInformation {
  title: string;
  type: AssetType;
  typeOfContent: string;
}
