import {Component, OnInit} from '@angular/core';
import {signOut} from 'aws-amplify/auth';
import {Router} from '@angular/router';

@Component({
  selector: 'app-external-logout',
  templateUrl: './external-logout.component.html',
  styleUrl: './external-logout.component.scss'
})
export class ExternalLogoutComponent implements OnInit {
  constructor(private router: Router) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      signOut().then(() => {
        this.router.navigate(['/']).then();
      });
    }, 1000);
  }
}
