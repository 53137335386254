import {Component, Input} from '@angular/core';
import {fadeIn} from '../../../animations/animation';

@Component({
  selector: 'app-authentication-flow',
  templateUrl: './authentication-flow.component.html',
  styleUrl: './authentication-flow.component.scss',
  animations: [fadeIn]
})
export class AuthenticationFlowComponent {
  @Input() currentStep = AuthenticationFlowStep.SignIn;
  currentUserEmail: string;

  protected readonly AuthenticationFlowStep = AuthenticationFlowStep;

  processStepEvent(event: AuthenticationFlowEvent) {
    this.currentStep = event.flowStep;
    this.currentUserEmail = event.currentUserEmail;
  }
}

export enum AuthenticationFlowStep {
  SignIn,
  NewPassword,
  ForgotPassword,
  ForgotPasswordVerificationCode,
  ConfirmWithTOTP
}

export interface AuthenticationFlowEvent {
  flowStep: AuthenticationFlowStep,
  currentUserEmail: string | null
}
